<template>
    <div>
        <div class="flex flex-col flex-1 h-full">
            <div class="flex p-4 mb-6">
                <div class="w-1/2 bg-gray-100 p-4">
                    <div class="flex justify-between">
                        <div class="text-black capitalize font-bold text-sm">Community</div>
                        <amenities-picker
                            id="communityAmenityPicker"
                            type="Community"
                            icon-picker-direction="left"
                            :customer-community-amenities="getCustomerCommunityAmenitiesByType('Community')"
                            @amenity-picked="addCustomerCommunityAmenity"
                        ></amenities-picker>
                    </div>
                    <amenities-list
                        group="Community"
                        :editable="true"
                        :customer-community-amenities="getCustomerCommunityAmenitiesByType('Community')"
                        @remove-amenity="removeCustomerCommunityAmenity"
                    ></amenities-list>
                </div>
                <div class="w-1/2 ml-4 p-4">
                    <div class="flex justify-between">
                        <div class="text-black capitalize font-bold text-sm">Apartment</div>
                        <amenities-picker
                            id="apartmentAmenityPicker"
                            type="Apartment"
                            icon-picker-direction="right"
                            :customer-community-amenities="getCustomerCommunityAmenitiesByType('Apartment')"
                            @amenity-picked="addCustomerCommunityAmenity"
                        ></amenities-picker>
                    </div>
                    <amenities-list
                        group="Apartment"
                        :editable="true"
                        :customer-community-amenities="getCustomerCommunityAmenitiesByType('Apartment')"
                        @remove-amenity="removeCustomerCommunityAmenity"
                    ></amenities-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Auth from "@/mixins/Auth";
    import {mapActions, mapGetters} from "vuex";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import AmenitiesList from "@/components/cms/websites/AmenitiesList";
    import AmenitiesPicker from "@/components/cms/websites/AmenitiesPicker";

    export default {
        mixins: [ ModalNavigation, Auth ],
        components: {AmenitiesPicker, AmenitiesList },
        data: () => {
            return {
                website: {},
                amenities: {
                    Community: [],
                    Apartment: [],
                }
            }
        },
        computed: {
            ...mapGetters({
                getCustomerAmenities: 'amenities/getCustomerAmenities',
                getCustomerCommunityAmenitiesByType: 'amenities/getCustomerCommunityAmenitiesByType',
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
        },
        methods: {
            ...mapActions({
                updateWebsiteInStore: 'websites/updateWebsite',
                loadAmenitiesIconsInStore: 'amenities/loadAmenitiesIcons',
                loadCustomerAmenitiesInStore: 'amenities/loadCustomerAmenities',
                loadCustomerCommunityAmenitiesInStore: 'amenities/loadCustomerCommunityAmenities',
                addCustomerCommunityAmenityToStore: 'amenities/addCustomerCommunityAmenity',
                removeCustomerCommunityAmenityFromStore: 'amenities/removeCustomerCommunityAmenity',
                notifySuccess: 'alerts/notifySuccess',
                notifyError: 'alerts/notifyError',
            }),
            fetchAmenitiesIcons() {
                this.$cmsDataProvider.get('amenitiesIcons')
                    .then(response => {
                        this.loadAmenitiesIconsInStore(response);
                    })
                    .catch((err) => this.notifyError(err));
            },
            addCustomerCommunityAmenity(payload) {
                let amenities = this.getCustomerCommunityAmenitiesByType(payload.type);
                let order = amenities.length ? ( amenities.slice(-1)[0].order + 1) : 0;

                this.$cmsDataProvider.create('createCustomerCommunityAmenity', {
                    communityId: this.website.communities_id,
                    data: {
                        customer_community_amenities_name: payload.amenity,
                        order: order,
                        type: payload.type,
                        icon: payload.icon,
                    }
                })
                .then( (response) => {
                  if(response.status == "warning"){
                    this.notifyError(response.message);
                  }
                  else{
                    this.notifySuccess('The amenity was added successfully');
                    this.addCustomerCommunityAmenityToStore({
                        amenity: payload.amenity,
                        type: payload.type,
                        icon: payload.icon,
                        order: order
                    });
                  }
                })
                .catch((err) => this.notifyError(err));
            },
            removeCustomerCommunityAmenity(payload) {
                this.$cmsDataProvider.create('deleteCustomerCommunityAmenity', {
                    communityId: this.website.communities_id,
                    data: {
                        amenityName: payload.amenity
                    }
                })
                .then( () => {
                    this.removeCustomerCommunityAmenityFromStore(payload);
                    this.notifySuccess('The amenity was removed successfully');
                })
                .catch((err) => this.notifyError(err));
            },
            fetchCustomerAmenities() {
                this.$cmsDataProvider.get('customerAmenities')
                .then(response => {
                    this.loadCustomerAmenitiesInStore(response);
                })
                .catch((err) => this.notifyError(err));
            },
            fetchCustomerCommunityAmenities() {
                this.$cmsDataProvider.get('customerCommunityAmenities', { communityId: this.website.communities_id })
                    .then(response => {
                        let data = { Community: [], Apartment: [] };
                        response.forEach( item => {
                            let type = item.amenity_details.community_amenities_type_name;

                            data[type].push({
                                id: item.amenity_details.id_customer_community_amenities,
                                type: type,
                                amenity: item.amenity_details.community_amenities_name,
                                icon: item.amenity_details.amenity_icons_name,
                                order: item.order
                            });
                        });
                        this.loadCustomerCommunityAmenitiesInStore(data);
                    })
                    .catch((err) => this.notifyError(err));
            }         
        },
        async mounted() {
            this.website = this.getWebsiteInfo
            this.fetchAmenitiesIcons();
            this.fetchCustomerCommunityAmenities();
            this.fetchCustomerAmenities();

        },
    }
</script>